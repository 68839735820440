import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import faqback from "../Assets/Images/experience.webp";
import AxiosInstance from "../Config/AxiosInstance";
import APILIST from "../Config/ApiList";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Loader from "../Components/Loader";
const FAQs = () => {
  const [faqData, setFaqData] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const getFaqData = async () => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance.get(APILIST.FAQ_GET);
      if (response && response?.data?.s === 1) {
        setFaqData(response?.data?.r);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFaqData();
  }, []);
  window.scrollTo({ top: 0, behavior: "instant" });
  console.log(faqData);
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "100%", paddingTop: "91px", height: "100%" }}>
          <Box
            sx={{
              backgroundImage: `url(${faqback})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              p: { xs: 2, md: 5 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                maxWidth: "800px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "30px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "30px", lg: "46px" },
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                FAQ's
              </Typography>
              <Typography
                sx={{
                  color: "#696969",
                  fontSize: { xs: "18px", sm: "22px", md: "24px" },
                  textAlign: "center",
                }}
              >
                Find answers to commom questions about our services,features and
                how to use YANDI
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: {xs:"98%",sm:"800px"},
                height: "500px",
                p: { xs: 1.2, md: 5 },
                m: { xs: 1, md: 8 },
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                overflow: "scroll",
                borderRadius: "12px",
              }}
            >
              {!loading ? (
                faqData.map((item, index) => {
                  return (
                    <Accordion key={index} sx={{ py: 1.3 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          fontWeight: "600",
                          fontSize: { xs: "16px", md: "20px" },
                        }}
                      >
                        {item.question}
                      </AccordionSummary>
                      <AccordionDetails>{item.answer}</AccordionDetails>
                    </Accordion>
                  );
                })
              ) : (
                <Loader />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FAQs;
