import React from 'react'
import Home from '../Pages/Home'
import Features from '../Pages/Features'
import Services from '../Pages/Services'
import BecomeADriver from '../Pages/BecomeADriver'
import About from '../Pages/About'
import Download from '../Pages/Download'

const Body = () => {
  return (
    <>
      <Home/>
      <Features/>
      <Services/>
      <BecomeADriver/>
      <About/>
      <Download/>
    </>
  )
}

export default Body
