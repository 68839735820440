import {
  Box,
  IconButton,
  Modal,
  Typography,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { Link } from "react-scroll";
import logo from "../Assets/Images/Footerlogo.png";
import instagram from "../Assets/Images/Instagram.png";
import facebook from "../Assets/Images/Facebook.png";
import linkedin from "../Assets/Images/LinkedIn.png";
import twitter from "../Assets/Images/Twitter.png";
import tiktok from "../Assets/Images/tiktok.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import AxiosInstance from "../Config/AxiosInstance";
import APILIST from "../Config/ApiList";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const handleModalToggle = () => {
    setOpenModal(!openModal);
  };
  const handleMenuClick = () => {
    if (
      location.pathname === "/faq" ||
      location.pathname === "/privacypolicy"
    ) {
      navigate("/");
    }
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .required("Email address is required!")
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Please Provide Valid Email"
        ),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("message", values.message);
        const response = await AxiosInstance.post(
          APILIST.CONTACT_US_POST,
          formData
        );
        if (response && response.data.s === 1) {
          toast.success("Submitted Sucessfully");
          resetForm();
        }
      } catch (error) { }
    },
  });

  return (
    <>
      <Box
        sx={{
          width: "100%",
          background: "#142328",
          px: { xs: 2, md: 15 },
          py: { xs: 1, md: 5 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            gap: { xs: "30px", lg: "0px" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", lg: "start" },
              gap: "20px",
            }}
          >
            <Box sx={{ width: "200px" }}>
              <img src={logo} alt="" style={{ width: "100%" }} />
            </Box>
            <Typography
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                color: "white",
                fontWeight: "200",
                textAlign: { xs: "center", lg: "start" },
              }}
            >
              {t("footer.logoSection.content")}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                color: "white",
                cursor: 'pointer',
                fontWeight: "200",
              }}
            >
              <a href={`mailto:${t("footer.logoSection.email")}`} style={{ color: "white" }}>
                {t("footer.logoSection.email")}
              </a>
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                color: "white",
                fontWeight: "200",
              }}
            >
              {t("footer.logoSection.contactNumber")}
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}></Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", lg: "start" },
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "20px", md: "24px" },
                color: "white",
                fontWeight: "400",
              }}
            >
              {t("footer.menu1.head")}
            </Typography>
            <Link to="home" smooth={true} duration={500} offset={-100}>
              <Typography
                sx={{
                  fontSize: { xs: "16px", md: "20px" },
                  color: "white",
                  fontWeight: "200",
                  cursor: "pointer",
                  transition: "0.5s",
                  "&:hover": { color: "#00E676" },
                }}
                onClick={() => handleMenuClick("home")}
              >
                {t("footer.menu1.subHead1")}
              </Typography>
            </Link>
            <Link to="features" smooth={true} duration={500} offset={-100}>
              <Typography
                sx={{
                  fontSize: { xs: "16px", md: "20px" },
                  color: "white",
                  fontWeight: "200",
                  cursor: "pointer",
                  transition: "0.5s",
                  "&:hover": { color: "#00E676" },
                }}
                onClick={() => handleMenuClick("features")}
              >
                {t("footer.menu1.subHead2")}
              </Typography>
            </Link>
            <Link to="services" smooth={true} duration={500} offset={-100}>
              <Typography
                sx={{
                  fontSize: { xs: "16px", md: "20px" },
                  color: "white",
                  fontWeight: "200",
                  cursor: "pointer",
                  transition: "0.5s",
                  "&:hover": { color: "#00E676" },
                }}
                onClick={() => handleMenuClick("services")}
              >
                {t("footer.menu1.subHead3")}
              </Typography>
            </Link>

            <Link to="become-driver" smooth={true} duration={500} offset={-100}>
              <Typography
                sx={{
                  fontSize: { xs: "16px", md: "20px" },
                  color: "white",
                  fontWeight: "200",
                  cursor: "pointer",
                  transition: "0.5s",
                  "&:hover": { color: "#00E676" },
                }}
                onClick={() => handleMenuClick("become-driver")}
              >
                {t("footer.menu1.subHead4")}
              </Typography>
            </Link>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: { xs: "center", lg: "start" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "18px", md: "22px" },
                color: "white",
                fontWeight: "400",
              }}
            >
              {t("footer.menu2.head")}
            </Typography>
            <Link to="about" smooth={true} duration={500} offset={-100}>
              <Typography
                sx={{
                  fontSize: { xs: "16px", md: "20px" },
                  color: "white",
                  fontWeight: "200",
                  cursor: "pointer",
                  transition: "0.5s",
                  "&:hover": { color: "#00E676" },
                }}
                onClick={() => handleMenuClick("about")}
              >
                {t("footer.menu2.subHead1")}
              </Typography>
            </Link>
            <Typography
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                color: "white",
                fontWeight: "200",
                cursor: "pointer",
                transition: "0.5s",
                "&:hover": { color: "#00E676" },
              }}
              onClick={() => setOpenModal(true)}
            >
              {t("footer.menu2.subHead2")}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                color: "white",
                fontWeight: "200",
                cursor: "pointer",
                transition: "0.5s",
                "&:hover": { color: "#00E676" },
              }}
              onClick={() => navigate("/faq")}
            >
              {t("footer.menu2.subHead3")}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                color: "white",
                fontWeight: "200",
                cursor: "pointer",
                transition: "0.5s",
                "&:hover": { color: "#00E676" },
              }}
              onClick={() => navigate("/privacypolicy")}
            >
              {t("footer.menu2.subHead4")}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "18px", md: "22px" },
                color: "white",
                fontWeight: "400",
              }}
            >
              {t("footer.menu3.head")}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", gap: "5px" }}>
              <a href="https://www.facebook.com/profile.php?id=61564962590351&mibextid=LQQJ4d"><img src={facebook} alt="facebook" /></a>
              <a href="https://x.com/yandiapp"><img src={twitter} alt="twitter" /></a>
              <a href="https://www.instagram.com/yandi.app?igsh=MTB0bTRmbmZjc285cw%3D%3D&utm_source=qr"><img src={instagram} alt="instagram" /></a>
              <a href="https://youtube.com/@yandiapp?feature=shared"><img src={linkedin} alt="youtube" /></a>
              <a href="https://www.tiktok.com/@yandi.app?_t=8p41imjcxnS&_r=1"><img src={tiktok} alt="tiktok" /></a>
            </Box>
          </Box>
        </Box>
        <Divider
          sx={{
            background: "#787878",
            border: "1px solid #787878",
            width: "100%",
            mt: 2,
          }}
        />
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              color: "white",
              fontSize: { xs: "14px", lg: "18px" },
              fontWeight: "200",
              py: 2,
            }}
          >
            {t("footer.copyRight")}
          </Typography>
        </Box>
      </Box>
      <Modal open={openModal} onClose={handleModalToggle}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 330, sm: 400, md: 600 },
            bgcolor: "background.paper",
            borderRadius: 5,
            boxShadow: 24,
            p: { xs: 2, md: 4 },
            display: "flex",
            flexDirection: "column",
            gap: { xs: 1.5, md: 2 },
            border: "none",
            outline: "none",
            height: { xs: "350px", md: "500px" },
            overflow: "scroll",
          }}
        >
          <IconButton
            sx={{ alignSelf: "flex-end" }}
            onClick={handleModalToggle}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="h2"
            sx={{ fontSize: { xs: "22px", sm: "24px", md: "38px" } }}
          >
            {t("modal.head")}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{
              fontSize: { xs: "14px", sm: "16px", md: "20px" },
              color: "#000000B2",
            }}
          >
            {t("modal.subHead")}
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              return false;
            }}
          >
            <TextField
              name="name"
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleBlur}
              onChange={(e) => {
                const { value } = e.target;
                if (value.trimStart() !== value) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
              placeholder={t("modal.Name")}
              sx={{
                outline:
                  formik.touched.name && formik.errors.name
                    ? "1.5px solid red"
                    : "none",
                width: "100%",
                mt: "10px",
                "& .MuiOutlinedInput-notchedOutline ": {
                  outline: "none",
                  border: "none",
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                  border: "none",
                  background: "white",
                  fontSize: "18px",
                  borderRadius: "12px",
                },
                boxShadow: "0px 4px 4px 0px #00000040",
                borderRadius: "12px",
              }}
            />
            {formik.touched.name && formik.errors.name ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: "400",
                  fontSize: { xs: "14px", sm: "14px" },
                  marginTop: "5px",
                  paddingLeft: "10px",
                }}
              >
                {formik.errors.name}
              </Typography>
            ) : null}
            <TextField
              name="email"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleBlur}
              onChange={(e) => {
                const { value } = e.target;
                if (value.trimStart() !== value) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
              placeholder={t("modal.Email")}
              sx={{
                outline:
                  formik.touched.email && formik.errors.email
                    ? "1.5px solid red"
                    : "none",
                width: "100%",
                mt: "10px",
                "& .MuiOutlinedInput-notchedOutline ": {
                  outline: "none",
                  border: "none",
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                  border: "none",
                  background: "white",
                  fontSize: "18px",
                  borderRadius: "12px",
                },
                boxShadow: "0px 4px 4px 0px #00000040",
                borderRadius: "12px",
              }}
            />
            {formik.touched.email && formik.errors.email ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: "400",
                  fontSize: { xs: "14px", sm: "14px" },
                  marginTop: "5px",
                  paddingLeft: "10px",
                }}
              >
                {formik.errors.email}
              </Typography>
            ) : null}
            <TextField
              name="message"
              value={formik.values.message}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleBlur}
              onChange={(e) => {
                const { value } = e.target;
                if (value.trimStart() !== value) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
              placeholder={t("modal.Message")}
              multiline
              rows={5}
              sx={{
                outline:
                  formik.touched.message && formik.errors.message
                    ? "1.5px solid red"
                    : "none",
                width: "100%",
                mt: "10px",
                "& .MuiOutlinedInput-notchedOutline ": {
                  outline: "none",
                  border: "none",
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                  border: "none",
                  background: "white",
                  fontSize: "18px",
                  borderRadius: "12px",
                },
                boxShadow: "0px 4px 4px 0px #00000040",
                borderRadius: "12px",
              }}
            />
            {formik.touched.message && formik.errors.message ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: "400",
                  fontSize: { xs: "14px", sm: "14px" },
                  marginTop: "5px",
                  paddingLeft: "10px",
                }}
              >
                {formik.errors.message}
              </Typography>
            ) : null}
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  background: "#142328",
                  borderRadius: "12px",
                  p: 1,
                  mt: 2,
                  width: "250px",
                  "&:hover": { background: "#142328" },
                  textTransform: "none",
                }}
              >
                {t("modal.button")}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default Footer;
