import { Box } from "@mui/material";
import React from "react";
import {BounceLoader} from "react-spinners"
const Loader = () => {
  return (
    <>
      <Box sx={{ width: "100%", height: "100%",display:'flex',justifyContent:"center",alignItems:'center' }}>
        <BounceLoader color="#05E678" />
      </Box>
    </>
  );
};

export default Loader;
