import { Box, Typography } from "@mui/material";
import googleplay from "../Assets/Images/googleplay.png";
import appstore from "../Assets/Images/appstore.png";
import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import "./Welcome.scss";
import { useTranslation } from "react-i18next";
import experiencebanner from "../Assets/Images/experience.webp";

const Download = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            backgroundImage: `url(${experiencebanner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            p: { xs: 2, md: 5 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "50px",
          }}
        >
          <Box
            sx={{
              maxWidth: "600px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "30px", lg: "46px" },
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {t("experienceYandi.head")}
            </Typography>
            <Typography
              sx={{
                color: "#696969",
                fontSize: { xs: "20px", sm: "26px", md: "26px" },
              }}
            >
              {t("experienceYandi.subHead")}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "30px",
              flexDirection: { xs: "row", md: "row" },
            }}
          >
            <img src={googleplay} alt="" style={{ width: "120px",cursor:'pointer' }} />
            <img src={appstore} alt="" style={{ width: "120px",cursor:'pointer' }} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Download;
