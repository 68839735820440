import { Box, Typography } from "@mui/material";
import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import "./Welcome.scss";
import aboutimage from "../Assets/Images/aboutimage.webp";
import ScrollAnimation from "react-animate-on-scroll";
import {useTranslation} from "react-i18next"
const About = () => {
  const {t} = useTranslation()
  return (
    <>
       <div
        className="about"
        id="about"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: " 0 100px 100px 100px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              width: "80%",
              alignItems: "center",
            }}
          >
            <ScrollAnimation animateIn="animate__zoomIn">
            <Typography
              sx={{
                color: "#000000",
                fontSize: { xs: "28px", lg: "46px" },
                fontWeight: "500",
              }}
            >
              {t("about.head")}
            </Typography>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__zoomIn">
            <Typography
              sx={{
                color: "#696969",
                fontSize: { xs: "16px", sm: "26px", md: "26px" },
                textAlign: "center",
                maxWidth: "600px",
              }}
            >
              {t("about.subHead")}
            </Typography>
            </ScrollAnimation>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              gap: "30px",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", lg: "40%" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={aboutimage} alt="" style={{ width: "90%" }} />
            </Box>
            <Box
              sx={{
                width: { xs: "100%", lg: "60%" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ maxWidth: "800px" }}>
              <ScrollAnimation animateIn="animate__zoomIn">
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "26px",
                      md: "26px",
                      lg: "26px",
                    },
                    color: "#696969",
                    textAlign: "justify",
                  }}
                >
                  {t("about.content")}
                </Typography>
                </ScrollAnimation>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  )
}

export default About
