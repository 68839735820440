import { Box, Typography } from "@mui/material";
import hand from "../Assets/Images/hand.png";
import googleplay from "../Assets/Images/googleplay.png";
import appstore from "../Assets/Images/appstore.png";
import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "./Welcome.scss";
import { useTranslation } from "react-i18next";

const Home = () => {
  const [showCarImages, setShowCarImages] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCarImages(false);
    }, 10500);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div
        id="home"
        style={{
          width: "100%",
          minHeight: "100vh",
          paddingTop: "91px",
          background:
            "linear-gradient(180deg, rgba(0, 230, 118, 0.25) 27.39%, rgba(0, 230, 118, 0.6) 66.39%, #00E676 100%)",
          display: "flex",
          gap: "0px",
        }}
      >
        <Box
          id="car-images-box"
          sx={{
            width: "100%",
            height: { xs: "230px", md: "300px", lg: "300px" },
            mt: "91px",
            position: "absolute",
            top: 0,
            left: 0,
            display: { xs: "none", md: "flex" },
            opacity: showCarImages ? 1 : 0,
            transition: "opacity 1s ease",
            zIndex: 2,
          }}
        ></Box>

        <Box
          sx={{
            width: "100%",
            height: { xs: "230px", md: "300px", lg: "300px" },
            mt: "91px",
            position: "absolute",
            top: 0,
            left: 0,
            display: { xs: "none", md: "flex" },
            opacity: showCarImages ? 0 : 1,
            transition: "opacity 1s ease",
            zIndex: 1,
          }}
          className="car-image"
        ></Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            width: "100%",
            gap: { xs: "30px", sm: "50px" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              px: { xs: 1, md: 5 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                gap: { xs: "10px", md: "30px" },
                maxWidth: "600px",
                zIndex: "20",
              }}
            >
              <Typography
                className="animate__animated  animate__zoomIn"
                sx={{
                  fontSize: { xs: "30px", md: "28px", lg: "32px" },
                  fontWeight: "700",
                }}
              >
                {t("home.head")}
              </Typography>
              <Typography
                sx={{ fontSize: { xs: "16", md: "18px", lg: "20px" } }}
              >
                {t("home.subHead")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: "30px",
                  flexDirection: { xs: "row", md: "row" },
                }}
              >
                <img
                  src={googleplay}
                  alt=""
                  style={{ width: "120px", cursor: "pointer" }}
                />
                <img
                  src={appstore}
                  alt=""
                  style={{ width: "120px", cursor: "pointer" }}
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: { xs: "center", md: "center", lg: "end" },
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                gap: "30px",
                maxWidth: "700px",
                zIndex: "99",
              }}
            >
              <img src={hand} alt="" style={{ width: "100%" }} />
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Home;
