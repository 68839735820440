import "./App.css";
import { Routes, Route } from "react-router-dom";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import Cursor from "./Components/Cursor";
import Body from "./Layout/Body";
import FAQs from "./Pages/FAQs";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

function App() {
  return (
    <>
      <Cursor />
      <Header />
      <Routes>
        <Route path="/" element={<Body />} />
        <Route path="/faq" element={<FAQs />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
