import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import "./Welcome.scss";
import greenbackground from "../Assets/Images/greenbackground.png";
import screen1 from "../Assets/Images/device-frame1.png";
import screen2 from "../Assets/Images/device-frame2.png";
import screen3 from "../Assets/Images/device-frame3.png";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
const Services = () => {
  const { t } = useTranslation();
  gsap.registerPlugin(MotionPathPlugin, ScrollTrigger);

  useEffect(() => {
    gsap.set("#motionSVG", { scale: 0.7, autoAlpha: 1 });
    gsap.set("#tractor", { transformOrigin: "50% 50%" });

    gsap.to("#motionSVG", {
      motionPath: {
        path: "#motionPath",
        align: "#motionPath",
        alignOrigin: [0.5, 0.5],
        autoRotate: true,
      },
      scrollTrigger: {
        trigger: "#motionPath",
        start: "top 50%",
        end: "bottom 70%",
        scrub: 1,
        markers: false,
      },
      duration: 10,
      ease: "none",
      immediateRender: true,
    });

    gsap.fromTo(
      "#motionSVG",
      {
        opacity: 1,
        delay: 3,
        duration: 3,
        scrollTrigger: {
          trigger: "#motionPath",
          start: "top 90%",
          end: "bottom 20%",
        },
      },
      {
        opacity: 1,
        delay: 0,
        duration: 1,
        scrollTrigger: {
          trigger: "#motionPath",
          start: "top 10%",
          end: "bottom 10%",
          scrub: true,
        },
      }
    );

    gsap.set("#motionBall", {
      scale: 0.7,
      transformOrigin: "50% 50%",
      autoAlpha: 1,
    });
    gsap.from("#motionBall", {
      motionPath: {
        path: "#motionPathTwo",
        align: "#motionPathTwo",
        alignOrigin: [0.5, 0.5],
        autoRotate: true,
      },
      scrollTrigger: {
        trigger: "#motionPathTwo",
        start: "top 70%",
        end: "bottom 80%",
        scrub: 1,
        markers: false,
      },
      duration: 1,
      ease: "none",
      immediateRender: true,
    });

    gsap.fromTo(
      "#motionBall",
      {
        opacity: 1,
        delay: 5,
        duration: 5,
        scrollTrigger: {
          trigger: "#motionPathTwo",
          start: "top 10%",
          end: "bottom 80%",
        },
      },
      {
        opacity: 1,
        delay: 1,
        duration: 3,
        scrollTrigger: {
          trigger: "#motionPathTwo",
          start: "45% top",
          end: "30% bottom",
          scrub: true,
        },
      }
    );

    const hideItemWrapper = (selector, state) => {
      const element = document.querySelector(selector);
      if (element) {
        element.classList[state ? "add" : "remove"]("active");
      }
    };

    const addClassWrapper = (selector, state) => {
      const element = document.querySelector(selector);
      if (element) {
        element.classList[state ? "add" : "remove"]("success");
      }
    };

    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".rightPart",
          scrub: true,
          start: "3% top",
          end: "bottom center",
          markers: false,
          onEnterBack: () => {
            hideItemWrapper(".firstRight", true);
            addClassWrapper(".firstRight", false);
          },
          onEnter: () => {
            hideItemWrapper(".firstRight", true);
            addClassWrapper(".firstRight", false);
          },
          onLeaveBack: () => {
            hideItemWrapper(".firstRight", false);
            addClassWrapper(".firstRight", false);
          },
          onLeave: () => {
            hideItemWrapper(".firstRight", false);
            addClassWrapper(".firstRight", true);
          },
        },
      })
      .to(".firstRight", {
        scrollTrigger: {
          trigger: ".firstRight",
          start: "top top",
          end: "bottom center",
          scrub: true,
          markers: false,
        },
      });
  }, []);

  return (
    <>
      <div className="container" id="services">
        <section className="firstSection">
          <div className="Animation-svg">
            <svg
              className="roadsvg"
              id="linesvg Layer_1"
              version="1.1"
              viewBox="0 0 1512 2300"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ objectFit: "contain" }}
            >
              <path
                d="M1507.5 26.5L1466.94 67.318C1259.35 276.235 986.997 408.51 694.448 442.5V442.5L252.155 490.222C161.443 500.01 89.8772 571.806 80.382 662.55V662.55C69.2791 768.656 146.112 863.746 252.184 875.175L839.896 938.5L1250.7 952.01C1354.29 955.417 1438.66 1036.37 1446.33 1139.73V1139.73C1448.3 1166.32 1445.02 1193.03 1436.66 1218.34L1431.33 1234.49C1408.95 1302.3 1351.29 1352.44 1281.03 1365.2L864.529 1440.82C764.986 1458.89 667.587 1487.25 573.899 1525.43L-74 1789.5"
                stroke="#142328"
                stroke-opacity="0.03"
                stroke-width="73"
              />
              <path
                id="motionPath"
                className="st0"
                d="M1507.5 26.5L1466.94 67.318C1259.35 276.235 986.997 408.51 694.448 442.5V442.5L252.155 490.222C161.443 500.01 89.8772 571.806 80.382 662.55V662.55C69.2791 768.656 146.112 863.746 252.184 875.175L839.896 938.5L1250.7 952.01C1354.29 955.417 1438.66 1036.37 1446.33 1139.73V1139.73C1448.3 1166.32 1445.02 1193.03 1436.66 1218.34L1431.33 1234.49C1408.95 1302.3 1351.29 1352.44 1281.03 1365.2L864.529 1440.82C764.986 1458.89 667.587 1487.25 573.899 1525.43L-74 1789.5"
                stroke="#142328"
                stroke-opacity="0.1"
                stroke-width="2"
                stroke-dasharray="12 12"
              />
              <g id="motionSVG">
                <g id="tractor">
                  <svg
                    className="ball"
                    width="136"
                    height="70"
                    viewBox="0 0 136 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="1">
                      <path
                        d="M6.27249 46.204C5.97073 43.4773 5.66751 37.9924 5.66224 35.2496C5.6555 32.3085 6.02845 26.4344 6.27249 23.5042C7.0972 13.545 14.2322 5.72361 24.0519 5.72361L18.8912 4.84119C8.8222 4.84119 1.93567 13.545 1.11067 23.5042C0.869264 26.4344 0.495149 32.3085 0.500423 35.2496C0.507162 37.9924 0.81155 43.4773 1.11067 46.204C2.17005 55.8104 8.8222 64.8658 18.8912 64.8658L24.0519 63.9834C14.2322 63.9831 7.33333 55.8104 6.27249 46.204Z"
                        fill="#142328"
                      />
                      <path
                        d="M129.728 46.204C130.028 43.4773 130.333 37.9924 130.337 35.2496C130.344 32.3085 129.971 26.4344 129.728 23.5042C128.905 13.545 121.768 5.72361 111.949 5.72361L117.11 4.84119C127.179 4.84119 134.064 13.545 134.89 23.5042C135.132 26.4344 135.506 32.3085 135.5 35.2496C135.494 37.9924 135.189 43.4773 134.89 46.204C133.829 55.8104 127.179 64.8658 117.11 64.8658L111.949 63.9834C121.768 63.9831 128.668 55.8104 129.728 46.204Z"
                        fill="#142328"
                      />
                      <path
                        d="M20.8759 63.983C11.0574 63.983 4.15475 55.81 3.09655 46.2039C2.79479 43.4772 2.49039 37.9923 2.48631 35.2495C2.48103 32.3084 2.85368 26.4343 3.09655 23.5041C3.92009 13.5449 11.0574 5.72351 20.8759 5.72351L113.488 5.72351C123.307 5.72351 130.243 13.7635 131.269 23.5041C131.561 26.3136 131.768 30.5558 131.768 34.791C131.768 39.0677 131.494 43.3594 131.269 46.2039C130.473 56.1979 123.307 63.983 113.488 63.983H20.8759Z"
                        fill="#00E676"
                      />
                      <path
                        d="M76.7643 54.4718C81.8432 42.2088 81.4448 29.1031 77.0379 15.0295C83.6294 13.6173 94.4617 9.41559 100.9 9.92125C107.658 25.6042 107.717 44.0029 101.049 59.7626C94.4833 60.1932 84.225 55.7099 76.7643 54.4718Z"
                        fill="#142328"
                      />
                      <path
                        d="M47.1001 54.4718C42.0197 42.2088 42.417 29.1031 46.8253 15.0295C40.2323 13.6174 35.5747 13.7715 28.2106 14.1898C20.4777 27.1865 20.4121 42.4338 28.039 55.4923C35.5519 55.8506 39.6382 55.7099 47.1001 54.4718Z"
                        fill="#142328"
                      />
                      <path
                        d="M131.269 46.2039C131.494 43.3594 131.768 39.0677 131.768 34.791C131.768 30.5558 131.561 26.3139 131.269 23.5041C130.243 13.7635 123.307 5.72351 113.488 5.72351L20.8759 5.72351C11.0574 5.72351 3.92009 13.5449 3.09655 23.5041C2.85368 26.4343 2.48103 32.3084 2.48631 35.2495C2.49039 37.9923 2.79479 43.4772 3.09655 46.2039C4.15475 55.8103 11.0574 63.983 20.8759 63.983H113.488C123.307 63.983 130.473 56.1979 131.269 46.2039ZM23.8197 62.1297C14.6222 62.1297 8.16198 54.4773 7.16969 45.4808C6.88815 42.9288 6.60368 37.791 6.59841 35.2225C6.59431 32.4692 6.94294 26.9693 7.16969 24.2254C7.94079 14.8993 14.6222 7.57654 23.8197 7.57654L110.538 7.57654C119.733 7.57654 126.229 15.1055 127.188 24.2254C127.463 26.8539 127.656 30.8277 127.656 34.7933C127.656 38.7994 127.398 42.8198 127.188 45.4808C126.442 54.8406 119.733 62.1297 110.538 62.1297H23.8197Z"
                        fill="#00E676"
                      />
                      <path
                        opacity="1"
                        d="M95.6045 15.7445C101.534 29.4977 102.306 45.3376 97.9114 59.6541C99.0232 59.7789 100.077 59.8258 101.05 59.7628C107.718 44.0034 107.659 25.6047 100.901 9.9215C94.4623 9.41583 83.63 13.6176 77.0385 15.0297C77.4715 16.4069 77.8632 17.7789 78.2186 19.1389C84.1553 17.4288 91.0044 15.3836 95.6045 15.7445Z"
                        fill="#2C4C56"
                      />
                      <path
                        d="M85.6145 17.9854C85.6145 16.191 85.6625 14.4167 85.7499 12.6692C82.5553 13.5853 79.5017 14.5011 77.0381 15.0296C81.445 29.1033 81.8434 42.2086 76.7645 54.472C82.6655 55.4511 90.3167 58.4579 96.5071 59.461C89.9824 51.0211 85.6145 35.6078 85.6145 17.9854Z"
                        fill="#142328"
                      />
                      <path
                        opacity="1"
                        d="M35.3468 53.3936C26.6977 41.6576 26.7742 27.9566 35.5425 16.2783C37.9378 15.6786 40.2806 14.9532 42.5687 14.2961C37.9753 13.706 33.8969 13.8654 28.2106 14.1903C20.4777 27.187 20.4121 42.4342 28.0389 55.4927C33.5429 55.7544 37.2121 55.7462 41.713 55.2393C39.7173 54.6771 37.6263 54.0387 35.3468 53.3936Z"
                        fill="#2C4C56"
                      />
                      <path
                        d="M36.3766 35.4575C36.3766 42.5775 35.7221 49.3864 34.5285 55.6615C38.5721 55.6387 42.071 55.3047 47.1001 54.4721C42.0197 42.209 42.417 29.1033 46.8253 15.0297C42.2239 14.0438 38.5612 13.82 34.2657 13.9205C35.6242 20.5577 36.3766 27.8318 36.3766 35.4575Z"
                        fill="#142328"
                      />
                      <path
                        d="M64.1552 5.72351H49.5557C50.2128 15.3288 51.6252 26.9254 53.7657 39.3335C55.2947 48.1996 57.0352 56.5533 58.854 63.9833H74.5076C73.7406 55.7072 72.4957 46.3114 70.7845 36.3965C68.829 25.041 66.5236 14.5307 64.1552 5.72351Z"
                        fill="#00E676"
                      />
                      <path
                        d="M32.8038 62.0614L47.1671 57.2963H75.9932L99.5139 62.0614H32.8038Z"
                        fill="#142328"
                      />
                      <path
                        d="M32.8038 7.59705L47.1671 12.3634H75.9932L99.5139 7.59705L32.8038 7.59705Z"
                        fill="#142328"
                      />
                      <path
                        d="M101.022 10.2151C101.222 10.2421 101.433 10.2822 101.656 10.3411L118.326 14.5604C121.059 15.2967 124.427 17.692 126.49 20.5982C126.381 20.2133 126.263 19.8298 126.134 19.4553C124.018 16.8886 120.999 14.8701 118.427 14.1769L101.756 9.95906C100.988 9.75252 100.319 9.72849 99.7594 9.88259C100.152 9.88259 100.533 9.89343 100.901 9.92156C100.943 10.0194 100.98 10.117 101.022 10.2151Z"
                        fill="#00E676"
                      />
                      <path
                        d="M99.2321 59.7655C99.8852 60.1276 100.73 60.1733 101.755 59.8997L118.428 55.6792C121.037 54.9752 124.109 52.9059 126.229 50.2853C126.35 49.915 126.46 49.5409 126.561 49.16C124.504 52.1117 121.091 54.5501 118.328 55.2957L101.656 59.5135C101.463 59.5672 101.28 59.6009 101.105 59.629C101.086 59.6718 101.07 59.7186 101.05 59.7629C100.47 59.8004 99.8626 59.7989 99.2321 59.7655Z"
                        fill="#00E676"
                      />
                      <path
                        d="M126.712 20.2706C125.816 21.2618 124.688 18.7015 122.327 15.2952C120.043 11.9248 118.557 10.4512 120.275 9.92006C121.849 9.45073 125.343 10.1761 126.838 13.1603C128.42 16.0932 127.684 19.3226 126.712 20.2706Z"
                        fill="#F5FAFF"
                      />
                      <path
                        opacity="1"
                        d="M124.462 15.3581C124.182 15.3581 121.157 14.4086 121.157 13.2379C121.157 12.0684 124.182 11.1177 124.462 11.1177C124.741 11.1177 124.968 12.0684 124.968 13.2379C124.968 14.4086 124.742 15.3581 124.462 15.3581Z"
                        fill="#142328"
                      />
                      <path
                        d="M124.732 15.3581C124.453 15.3581 124.228 14.4086 124.228 13.2379C124.228 12.0684 124.453 11.1177 124.732 11.1177C125.014 11.1177 125.238 12.0684 125.238 13.2379C125.238 14.4086 125.014 15.3581 124.732 15.3581Z"
                        fill="#F5FAFF"
                      />
                      <path
                        d="M126.712 20.2706C126.826 20.1581 126.935 20.0146 127.039 19.8467C126.189 20.0023 125.066 17.6647 122.989 14.6673C121.32 12.2037 120.085 10.7568 120.206 9.94641C118.61 10.499 120.078 11.9755 122.328 15.2948C124.688 18.7015 125.816 21.2617 126.712 20.2706Z"
                        fill="#F5FAFF"
                      />
                      <path
                        d="M126.291 12.9561C127.951 16.0299 127.18 19.4122 126.16 20.406C126.144 20.4236 126.13 20.4303 126.115 20.4449C126.329 20.5214 126.525 20.4745 126.712 20.2706C127.684 19.3226 128.42 16.0929 126.838 13.16C126.123 11.7303 124.948 10.8236 123.743 10.3126C124.75 10.8786 125.68 11.7344 126.291 12.9561Z"
                        fill="#F5FAFF"
                      />
                      <path
                        d="M126.712 50.1092C125.816 49.1169 124.688 51.6757 122.327 55.082C120.043 58.4523 118.557 59.9289 120.275 60.4586C121.849 60.9265 125.343 60.2037 126.838 57.2184C128.42 54.2843 127.684 51.0561 126.712 50.1092Z"
                        fill="#F5FAFF"
                      />
                      <path
                        opacity="1"
                        d="M124.462 55.0217C124.182 55.0217 121.157 55.9712 121.157 57.1408C121.157 58.3115 124.182 59.261 124.462 59.261C124.741 59.261 124.968 58.3115 124.968 57.1408C124.968 55.9712 124.742 55.0217 124.462 55.0217Z"
                        fill="#142328"
                      />
                      <path
                        d="M124.732 55.0217C124.453 55.0217 124.228 55.9712 124.228 57.1408C124.228 58.3115 124.453 59.261 124.732 59.261C125.014 59.261 125.238 58.3115 125.238 57.1408C125.238 55.9712 125.014 55.0217 124.732 55.0217Z"
                        fill="#F5FAFF"
                      />
                      <path
                        d="M126.712 50.1092C126.826 50.219 126.935 50.3614 127.039 50.5316C126.189 50.3773 125.066 52.7122 122.989 55.711C121.32 58.1734 120.085 59.6204 120.206 60.4316C118.61 59.8779 120.078 58.4011 122.328 55.082C124.688 51.6757 125.816 49.1169 126.712 50.1092Z"
                        fill="#F5FAFF"
                      />
                      <path
                        d="M126.291 57.4222C127.951 54.3484 127.18 50.9661 126.16 49.9709C126.144 49.9547 126.13 49.948 126.115 49.9333C126.329 49.8569 126.525 49.9026 126.712 50.1091C127.684 51.056 128.42 54.2842 126.838 57.2186C126.123 58.6483 124.948 59.5547 123.743 60.0645C124.75 59.4982 125.68 58.6427 126.291 57.4222Z"
                        fill="#F5FAFF"
                      />
                      <path
                        d="M7.99307 50.182C8.49199 49.4874 10.4045 52.6388 12.1371 55.0353C13.752 57.2953 14.1554 56.9197 13.1698 57.9363C12.1869 58.7783 10.4716 60.0202 9.21211 57.1517C7.86416 54.2897 7.34268 50.6109 7.99307 50.182Z"
                        fill="#F5F9FF"
                      />
                      <path
                        opacity="1"
                        d="M10.6163 54.9748C10.8415 55.0366 12.9993 55.6776 12.9029 56.2812C12.8103 56.882 10.9409 58.3718 10.7958 58.5168C10.6294 58.6065 10.4888 57.8409 10.4203 56.8135C10.352 55.7834 10.4138 54.9654 10.6163 54.9748Z"
                        fill="#142328"
                      />
                      <path
                        opacity="1"
                        d="M10.4258 54.9721C10.6256 54.983 10.8655 55.7582 10.9019 56.7088C10.942 57.6598 10.7958 58.5059 10.6335 58.5932C10.4674 58.6843 10.3212 57.9012 10.2433 56.8498C10.1668 55.7995 10.2219 54.9627 10.4258 54.9721Z"
                        fill="#F5FAFF"
                      />
                      <path
                        d="M7.99304 50.1818C7.92331 50.2501 7.86706 50.3535 7.82136 50.4874C8.50398 50.5612 10.1818 53.3468 11.6877 55.4925C12.8839 57.2157 13.3547 57.4035 13.2111 57.8969C14.1351 56.926 13.73 57.2638 12.1368 55.0349C10.4045 52.6387 8.49196 49.4869 7.99304 50.1818Z"
                        fill="#F5FAFF"
                      />
                      <path
                        d="M9.58508 57.2681C8.23976 54.4035 7.7385 50.6377 8.44777 50.2395C8.45715 50.2287 8.4677 50.2287 8.4759 50.2193C8.26672 50.0758 8.10295 50.0462 7.99309 50.1818C7.3427 50.611 7.86418 54.2895 9.21213 57.1515C9.82502 58.4966 10.5249 58.9688 11.2037 58.9392C10.6377 58.8519 10.0811 58.3571 9.58508 57.2681Z"
                        fill="#F5FAFF"
                      />
                      <path
                        d="M7.99307 19.5331C8.49199 20.2266 10.4045 17.0748 12.1371 14.6784C13.752 12.4199 14.1554 12.7943 13.1698 11.7777C12.1869 10.9369 10.4716 9.69233 9.21211 12.5637C7.86416 15.4237 7.34268 19.101 7.99307 19.5331Z"
                        fill="#F5F9FF"
                      />
                      <path
                        opacity="1"
                        d="M10.6163 14.7385C10.8415 14.6782 12.9993 14.0357 12.9029 13.4337C12.8103 12.8342 10.9409 11.3401 10.7958 11.198C10.6294 11.1095 10.4888 11.8727 10.4203 12.9013C10.352 13.9285 10.4138 14.7479 10.6163 14.7385Z"
                        fill="#142328"
                      />
                      <path
                        opacity="1"
                        d="M10.4258 14.7385C10.6256 14.7332 10.8655 13.9554 10.9019 13.0058C10.942 12.0549 10.7958 11.2073 10.6335 11.1188C10.4674 11.0289 10.3212 11.8135 10.2433 12.8649C10.1668 13.9152 10.2219 14.7508 10.4258 14.7385Z"
                        fill="#F5FAFF"
                      />
                      <path
                        d="M7.99304 19.5332C7.92331 19.4662 7.86706 19.3601 7.82136 19.2274C8.50398 19.1498 10.1818 16.3654 11.6877 14.2223C12.8839 12.4991 13.3547 12.3098 13.2111 11.8191C14.1351 12.7888 13.73 12.4507 12.1368 14.6782C10.4045 17.0747 8.49196 20.2264 7.99304 19.5332Z"
                        fill="#F5FAFF"
                      />
                      <path
                        d="M9.58508 12.4439C8.23976 15.3098 7.7385 19.0744 8.44777 19.474C8.45715 19.4849 8.4677 19.4849 8.4759 19.4942C8.26672 19.6404 8.10295 19.67 7.99309 19.5332C7.3427 19.1014 7.86418 15.424 9.21213 12.5635C9.82502 11.2185 10.5249 10.745 11.2037 10.7731C10.6377 10.8613 10.0811 11.3576 9.58508 12.4439Z"
                        fill="#F5FAFF"
                      />
                      <path
                        d="M27.407 56.5935L27.3039 56.4327C27.2731 56.3802 24.01 51.1891 22.5296 46.8813C21.0422 42.5521 21.0434 38.2218 21.0478 35.3304V35.0662C21.0452 32.2004 21.0425 27.8764 22.5296 23.5487C24.0103 19.2397 27.2731 14.051 27.3039 14L27.407 13.8377L27.2488 13.729C27.0074 13.5626 21.3947 9.7446 17.4639 8.85925C17.2896 8.93572 17.122 9.01482 16.9515 9.09685C16.9796 9.14109 17.0253 9.17595 17.0816 9.18415C20.5887 9.84919 25.8885 13.3001 26.8665 13.9505C26.2993 14.8718 23.5017 19.5025 22.1523 23.4201C20.6435 27.8123 20.6491 32.1734 20.6517 35.0999V35.3641C20.6464 38.261 20.645 42.6183 22.1523 47.0105C23.5017 50.9307 26.2993 55.5588 26.8665 56.4801C25.972 57.0769 21.4603 60.0139 18.0138 61.0185C18.2136 61.0976 18.4149 61.1741 18.62 61.2465C22.4098 59.9952 27.0288 56.8516 27.2488 56.7013L27.407 56.5935Z"
                        fill="#00E676"
                      />
                      <path
                        d="M60.443 7.58914L61.6313 7.58093L62.0028 12.3581H60.8145L60.443 7.58914Z"
                        fill="#00E676"
                      />
                      <path
                        d="M60.443 62.0708L61.6313 62.0747L62.0028 57.2937H60.8145L60.443 62.0708Z"
                        fill="#00E676"
                      />
                      <path
                        d="M105.345 9.21456C104.468 10.1292 103.573 9.45069 103.099 7.7755C102.618 6.01446 101.942 4.31524 101.079 2.71153C100.242 1.19601 100.187 0.27052 101.924 0.328235C103.654 0.388586 104.393 0.262608 105.339 1.27511C106.16 2.1587 106.871 4.44825 106.957 5.64972C107.011 6.70382 106.217 8.30372 105.345 9.21456Z"
                        fill="#00E676"
                      />
                      <path
                        d="M105.345 60.7858C104.468 59.8726 103.573 60.5499 103.099 62.2263C102.618 63.9859 101.942 65.6851 101.079 67.2876C100.242 68.8043 100.187 69.7298 101.924 69.6721C103.654 69.6118 104.393 69.7392 105.339 68.7252C106.16 67.8428 106.871 65.5521 106.957 64.3506C107.011 63.2965 106.217 61.6978 105.345 60.7858Z"
                        fill="#00E676"
                      />
                    </g>
                  </svg>
                </g>
              </g>
            </svg>
          </div>
          <Box
            sx={{
              position: { xs: "relative", lg: "absolute" },
              width: { md: "80%", lg: "70%" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: { xs: "white", lg: "transparent" },
              gap: { xs: "100px", md: "120px", lg: "150px", xl: "250px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                width: "80%",
                alignItems: "center",
                pt: 5,
              }}
            >
              <ScrollAnimation animateIn="animate__zoomIn">
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "38px", lg: "46px" },
                    fontWeight: "500",
                  }}
                >
                  {t("services.head")}
                </Typography>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__zoomIn">
                <Typography
                  sx={{
                    color: "#696969",
                    fontSize: { xs: "14px", sm: "20px", md: "26px" },
                    textAlign: "center",
                    maxWidth: "600px",
                  }}
                >
                  {t("services.subHead")}
                </Typography>
              </ScrollAnimation>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                px: { xs: 2, sm: 20, md: 0 },
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box>
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                    width: {
                      xs: "100%", // 0px - 599px
                      sm: "100%", // 600px - 959px
                      md: "300px", // 960px - 1279px
                      lg: "350px", // 1280px and up
                      xl: "450px",
                    },
                    height: {
                      xs: "auto", // 0px - 599px
                      sm: "auto", // 600px - 959px
                      md: "300px", // 960px - 1279px
                      lg: "350px", // 1280px and up
                      xl: "450px",
                    },
                  }}
                >
                  <img src={greenbackground} alt="" style={{ width: "100%" }} />
                  <Box
                    sx={{ position: "absolute", width: "90%", height: "90%",px:3 }}
                  >
                    <img
                      src={screen1}
                      alt=""
                      style={{ width: "100%",height:"100%" }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", md: "80%" },
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    justifyContent: "center",
                    pt: { xs: 3, md: 0 },
                  }}
                >
                  <ScrollAnimation animateIn="animate__zoomIn">
                    <Typography
                      sx={{
                        fontSize: { xs: "24px", sm: "28px", lg: "36px" },
                        fontWeight: "600",
                      }}
                    >
                      {t("services.carRidingServices.head")}
                    </Typography>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="animate__zoomIn">
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "400",
                        color: "#696969",
                        fontSize: {
                          xs: "16px",
                          sm: "18px",
                          md: "24px",
                          lg: "28px",
                        },
                      }}
                    >
                      {t("services.carRidingServices.subHead")}
                    </Typography>
                  </ScrollAnimation>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                px: { xs: 2, sm: 20, md: 0 },
                flexDirection: { xs: "column-reverse", md: "row" },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", md: "80%" },
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    justifyContent: "center",
                    pt: { xs: 3, md: 0 },
                  }}
                >
                  <ScrollAnimation animateIn="animate__zoomIn">
                    <Typography
                      sx={{
                        fontSize: { xs: "24px", sm: "28px", lg: "36px" },
                        fontWeight: "600",
                      }}
                    >
                      {t("services.foodDeliveryServices.head")}
                    </Typography>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="animate__zoomIn">
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "400",
                        color: "#696969",
                        fontSize: {
                          xs: "16px",
                          sm: "18px",
                          md: "24px",
                          lg: "28px",
                        },
                      }}
                    >
                      {t("services.foodDeliveryServices.subHead")}
                    </Typography>
                  </ScrollAnimation>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                    width: {
                      xs: "100%", // 0px - 599px
                      sm: "100%", // 600px - 959px
                      md: "300px", // 960px - 1279px
                      lg: "350px", // 1280px and up
                      xl: "450px",
                    },
                    height: {
                      xs: "auto", // 0px - 599px
                      sm: "auto", // 600px - 959px
                      md: "300px", // 960px - 1279px
                      lg: "350px", // 1280px and up
                      xl: "450px",
                    },
                  }}
                >
                  <img src={greenbackground} alt="" style={{ width: "100%"}} />
                  <Box
                    sx={{
                      position: "absolute",
                      width: "90%",
                      height: "90%",
                      px:3 
                    }}
                  >
                    <img
                      src={screen2}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                px: { xs: 2, sm: 20, md: 0 },
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box>
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                    width: {
                      xs: "100%", // 0px - 599px
                      sm: "100%", // 600px - 959px
                      md: "300px", // 960px - 1279px
                      lg: "350px", // 1280px and up
                      xl: "450px",
                    },
                    height: {
                      xs: "auto", // 0px - 599px
                      sm: "auto", // 600px - 959px
                      md: "300px", // 960px - 1279px
                      lg: "350px", // 1280px and up
                      xl: "450px",
                    },
                    pb: { xs: 3, lg: 0 },
                  }}
                >
                  <img src={greenbackground} alt="" style={{ width: "100%" }} />
                  <Box
                    sx={{ position: "absolute", width: "90%", height: "90%",px:3  }}
                  >
                    <img
                      src={screen3}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", md: "80%" },
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    justifyContent: "center",
                    pt: { xs: 3, md: 0 },
                    pb: { xs: 3, lg: 0 },
                  }}
                >
                  <ScrollAnimation animateIn="animate__zoomIn">
                    <Typography
                      sx={{
                        fontSize: { xs: "24px", sm: "28px", lg: "36px" },
                        fontWeight: "600",
                      }}
                    >
                      {t("services.smallFreightTransportServices.head")}
                    </Typography>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="animate__zoomIn">
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "400",
                        color: "#696969",
                        fontSize: {
                          xs: "16px",
                          sm: "18px",
                          md: "24px",
                          lg: "28px",
                        },
                      }}
                    >
                      {t("services.smallFreightTransportServices.subHead")}
                    </Typography>
                  </ScrollAnimation>
                </Box>
              </Box>
            </Box>
          </Box>
        </section>
      </div>
    </>
  );
};

export default Services;
