import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import faqback from "../Assets/Images/experience.webp";
import AxiosInstance from "../Config/AxiosInstance";
import APILIST from "../Config/ApiList";
const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
      }, []);
    const [data, setData] = useState({})
    const getPrivacyPolicyData = async () => {
        try {
            const res = await AxiosInstance.get(APILIST.PRIVACY_POLICY)
            if (res?.data?.s === 1) {
                setData(res?.data?.r)
            }
        } catch (error) {
            console.log(error)
        }
        console.log(data)
    }

    useEffect(() => {
        getPrivacyPolicyData()
    }, [])
    return (
        <>
            <Box sx={{
                width: "100%",
                mt: "91px",
                minHeight: "100vh"
            }}>
                <Box
                    sx={{
                        backgroundImage: `url(${faqback})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        p: { xs: 2, md: 5 },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: "800px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: "30px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: { xs: "30px", lg: "46px" },
                                fontWeight: "600",
                                textAlign: "center",
                            }}
                        >
                            Privacy Policy
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{
                    width: "100%", p: 2, maxWidth: { xs: "95%", lg: "80%", xl: "70%" },
                    margin: "auto auto",
                    width: "100%",
                }}>
                    <Typography dangerouslySetInnerHTML={{ __html: data?.privacy_policy }} />
                </Box>
            </Box>
        </>
    )
}

export default PrivacyPolicy
