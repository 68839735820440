import { Box, Typography } from "@mui/material";
import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import "./Welcome.scss";
import becomedriverdivice from "../Assets/Images/becomedrivermobile.png";
import mobileback from "../Assets/Images/Vector.webp";
import { useTranslation } from "react-i18next";
import googleplay from "../Assets/Images/googleplay.png";
import appstore from "../Assets/Images/appstore.png";
const BecomeADriver = () => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="become-driver"
        id="become-driver"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: " 0 100px 100px 100px",
          background: "transparent",
          marginTop:"-300px"
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            background: "#142328",
            borderRadius: "25px",
            display: "flex",
            alignItems: { xs: "initial", lg: "center" },
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              py: { xs: 0, lg: 0 },
              gap: "20px",
            }}
          >
            <Box
              sx={{
                maxWidth: "800px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                px: { xs: 3, lg: 10 },
                py: { xs: 5, md: 5 },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "22px", md: "26px", lg: "32px" },
                  color: "white",
                  fontWeight: "600",
                }}
              >
                {t("becomeADriver.head")}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "16px", md: "18px", xl: "22px" },
                  color: "white",
                  fontWeight: "200",
                }}
              >
                {t("becomeADriver.subHead")}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <img
                  src={googleplay}
                  alt=""
                  style={{ width: "100px", cursor: "pointer" }}
                />
                <img
                  src={appstore}
                  alt=""
                  style={{ width: "100px", cursor: "pointer" }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "end",
              justifyContent: "center",
              background: `url(${mobileback})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "100% 100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
              }}
            >
              <img
                src={becomedriverdivice}
                alt=""
                style={{ width: "50%", paddingTop: "40px" }}
              />
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default BecomeADriver;
