import { Box, Typography } from "@mui/material";
import FeaturesIcon from "../Assets/Images/featuresicon.png";
import FeaturesIcon2 from "../Assets/Images/featuresicon2.png";
import FeaturesIcon3 from "../Assets/Images/featuresicon3.png";
import FeaturesIcon4 from "../Assets/Images/featuresicon4.png";
import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import "./Welcome.scss";
import ScrollAnimation from "react-animate-on-scroll";
import {useTranslation} from "react-i18next"
const Features = () => {
  const {t} = useTranslation()
  return (
    <>
      <div
        id="features"
        style={{
          width: "100%",
          background: "#EEFFF6",
          display: "flex",
          flexDirection: "column",
          padding: "100px 0px",
          gap: "50px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "80px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              width: "80%",
              alignItems: "center",
            }}
          >
            <ScrollAnimation animateIn="  animate__zoomIn animate__slower">
              <Typography
                sx={{
                  color: "#000000",
                  fontSize: { xs: "28px", md: "30px", lg: "46px" },
                  fontWeight: "500",
                }}
              >
                {t("features.head")}
              </Typography>
            </ScrollAnimation>
            <ScrollAnimation animateIn="  animate__zoomIn animate__slower">
              <Typography
                className="animate__animated  animate__zoomIn"
                sx={{
                  color: "#696969",
                  fontSize: { xs: "14px", sm: "20px", md: "26px" },
                  textAlign: "center",
                  maxWidth: "600px",
                }}
              >
                {t("features.subHead")}
              </Typography>
            </ScrollAnimation>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              gap: "40px",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
                maxWidth: "250px",
              }}
            >
              <ScrollAnimation animateIn="animate__flipInY">
                <img src={FeaturesIcon} alt="sss" style={{ width: "130px" }} />
              </ScrollAnimation>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#3F3F3F",
                  fontSize: "26px",
                  fontWeight: "600",
                }}
              >
                {t("features.box1.head")}
              </Typography>
              <Typography
                sx={{ textAlign: "center", color: "#696969", fontSize: "22px" }}
              >
                {t("features.box1.subHead")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
                maxWidth: "250px",
              }}
            >
              <ScrollAnimation animateIn="animate__flipInY">
                <img src={FeaturesIcon2} alt="sss" style={{ width: "130px" }} />
              </ScrollAnimation>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#3F3F3F",
                  fontSize: "26px",
                  fontWeight: "600",
                }}
              >
                {t("features.box2.head")}
              </Typography>
              <Typography
                sx={{ textAlign: "center", color: "#696969", fontSize: "22px" }}
              >
                {t("features.box2.subHead")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
                maxWidth: "250px",
              }}
            >
              <ScrollAnimation animateIn="animate__flipInY">
                <img src={FeaturesIcon3} alt="sss" style={{ width: "130px" }} />
              </ScrollAnimation>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#3F3F3F",
                  fontSize: "26px",
                  fontWeight: "600",
                }}
              >
                {t("features.box3.head")}
              </Typography>
              <Typography
                sx={{ textAlign: "center", color: "#696969", fontSize: "22px" }}
              >
                {t("features.box3.subHead")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
                maxWidth: "250px",
              }}
            >
              <ScrollAnimation animateIn="animate__flipInY">
                <img src={FeaturesIcon4} alt="sss" style={{ width: "130px" }} />
              </ScrollAnimation>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#3F3F3F",
                  fontSize: "26px",
                  fontWeight: "600",
                }}
              >
                {t("features.box4.head")}
              </Typography>
              <Typography
                sx={{ textAlign: "center", color: "#696969", fontSize: "22px" }}
              >
                {t("features.box4.subHead")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Features;
